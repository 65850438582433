import { mapGetters } from "vuex";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import ApiService from "@/core/services/api.service";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
export default {
  props: {
    modal: {
      default: false
    },
    value: Boolean
  },

  name: "shop-category",
  data() {
    return {
      valid: false,
      isSaving: false,
      shopCategory: {
        titleEn: "",
        titleMe: "",
        titleDe: "",
        published: false,
        isDefault: false,
        isAdvertRelevant: false,
        isCreatePostRelevant: false,
        iconNormalPngBase64: undefined,
        iconNormalSvgBase64: undefined,
        iconActivePngBase64: undefined,
        iconActiveSvgBase64: undefined,
        parentShopCategoryId: null,
        translationStrings: []
      },
      shopCategories: [],
      shopCategoriesAreLoading: false,
      shopCategoryId: null,
      shopCategoryType: ["REGULAR", "SHOPPING_NEWS_SWIPE", "TOP", "VISIT_SHOP"],
      requiredRule: [v => !!v || "Field is required"]
    };
  },
  created() {
    this.searchShopCategory();
    this.shopCategoryId = this.$route.params.shopCategoryId;
    let title =
      this.shopCategoryId != null
        ? this.$i18n.t('pages.shopCategories.detailsTitle')
        : this.$i18n.t('pages.shopCategories.insertTitle');
    this.$store.dispatch(SET_BREADCRUMB, [{ title: title }]);
    if (this.shopCategoryId != null) {
      ApiService.get(`api/shop-category`, `${this.shopCategoryId}`)
        .then(response => {
          this.$log.debug("Shop category: ", response.data);
          this.shopCategory = response.data;
            this.shopCategory.parentShopCategoryId = response.data.parentShopCategory != null ? response.data.parentShopCategory.id : null;
            let titleEn = response.data.translationStrings.filter(obj => {
              return obj.languageCode === "ENG"
            })[0]
            this.shopCategory.titleEn = titleEn.text;
            let titleMe = response.data.translationStrings.filter(obj => {
              return obj.languageCode === "MNE"
            })[0]
            this.shopCategory.titleMe = titleMe.text;
            let titleDe = response.data.translationStrings.filter(obj => {
              return obj.languageCode === "GER"
            })[0]
            this.shopCategory.titleDe = titleDe.text;
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    }
  },
  methods: {
    searchShopCategory() {
      // Items have already been loaded
      if (this.shopCategories.length > 0) return;

      // Items have already been requested
      if (this.shopCategoriesAreLoading) return;

      this.shopCategoriesAreLoading = true;
      ApiService.query("api/shop-category")
          .then(res => {
            this.shopCategories = res.data;
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => (this.shopCategoriesAreLoading = false));
    },
    previousState() {
      this.$router.go(-1);
    },
    endsWith(suffix, str) {
      return str.indexOf(suffix, str.length - suffix.length) !== -1;
    },
    paddingSize(value) {
      if (this.endsWith("==", value)) {
        return 2;
      }
      if (this.endsWith("=", value)) {
        return 1;
      }
      return 0;
    },
    size(value) {
      return (value.length / 4) * 3 - this.paddingSize(value);
    },
    byteSize(base64String) {
      return this.formatAsBytes(this.size(base64String));
    },
    formatAsBytes(size) {
      return size.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " bytes";
    },
    handlePngNormal(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          this.shopCategory.iconNormalPngBase64 = base64Data;
        });
      }
    },
    handlePngActive(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          this.shopCategory.iconActivePngBase64 = base64Data;
        });
      }
    },
    handleSvgNormal(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          this.shopCategory.iconNormalSvgBase64 = base64Data;
        });
      }
    },
    handleSvgActive(event) {
      if (event && event.target.files && event.target.files[0]) {
        const file = event.target.files[0];
        // this.toBase64(file);
        this.toBase64(file, base64Data => {
          this.shopCategory.iconActiveSvgBase64 = base64Data;
        });
      }
    },
    toBase64(file, cb) {
      const fileReader = new FileReader();
      fileReader.onload = e => {
        const base64Data = e.target.result;
        cb(base64Data);
      };
      fileReader.readAsDataURL(file);
    },
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    },
    validateAndSave(){
      if (this.$refs.form.validate()) {
        this.saveShopCategory(this.shopCategory);
      }
    },
    selectIconNormalPng(file) {
      console.log("File: " + file)
      this.shopCategory.iconNormalPng = file;
    },
    saveShopCategory(shopCategory) {
      this.isSaving = true;
      this.loading = true;
      if (shopCategory.id != null) {
        ApiService.put(`api/shop-category`, shopCategory)
          .then(response => {
            this.$log.debug("Shop category updated: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.previousState();
          });
      } else {

        ApiService.post(`api/shop-category`, shopCategory)
          .then(response => {
            this.$log.debug("Shop category created: " + response);
          })
          .catch(error => {
            this.$log.error("Error: ", error);
            this.errored = true;
          })
          .finally(() => {
            this.loading = false;
            this.isSaving = false;
            this.previousState();
          });
      }
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    show: {
      get() {
        return this.value;
      },
      set(value) {
        if (!value) {
          this.$emit("input", value);
        }
      }
    }
  }
};
